import { CircularProgress } from '@mui/material'
import { memo } from 'react'

export const LoaderWhere = memo(function LoaderWhere(): JSX.Element {
  return (
    <div className="margin-top-xs margin-bottom-xs">
      <CircularProgress size={'170px'} thickness={2.3} />
    </div>
  )
})
