import { Alert } from '@interface/alert'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '.'

interface AlertSliceInterface {
  alert?: Alert
}

const initialState: AlertSliceInterface = {
  alert: undefined
}

export const alertSlice = createSlice({
  name: 'Alert',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<Alert>) => {
      if (state.alert === undefined) {
        state.alert = action.payload
      }
    },
    reset: () => initialState
  }
})

export const { setAlert, reset } = alertSlice.actions

export const selectedAlert = (state: RootState): Alert | undefined =>
  state.alert?.alert

// eslint-disable-next-line import/no-default-export
export default alertSlice.reducer
