import { AppointmentSlotCache } from '@interface/appointment-slot'
import { getClient } from '@lib/info'

let WHEN: AppointmentSlotCache | undefined

export const getWhen = (): AppointmentSlotCache | undefined => {
  return WHEN
}

export const setWhen = (when: AppointmentSlotCache): void => {
  const {
    company: { isUserSelectable }
  } = getClient()
  WHEN = { ...when, user: isUserSelectable ? when?.user : undefined }
}

export const eraseWhen = (): void => {
  WHEN = undefined
}
