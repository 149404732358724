import './subtitle.css'

import { Typography, useTheme, Link } from '@mui/material'
import { resetCoordinates, selectedCoordinates } from '@store/coordinates'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { memo } from 'react'
import { Trans } from 'react-i18next'
import { Else, If, Then } from 'react-if'

interface SubtitleProps {
  isGoogleMapsActive: boolean
}

export const Subtitle = memo(function Subtitle({
  isGoogleMapsActive
}: SubtitleProps): JSX.Element {
  const coordinates = useAppSelector(selectedCoordinates)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  return (
    <div className="point-of-sale-subtitle">
      <If condition={isGoogleMapsActive}>
        <Then>
          <>
            <Typography variant="contentTitle2" className="subtitle">
              <If condition={coordinates.text !== ''}>
                <Then>
                  <Trans>{coordinates.text}</Trans>
                </Then>
                <Else>
                  <Trans>pages.where.your_position</Trans>
                </Else>
              </If>
            </Typography>
            <div
              style={{
                backgroundColor: theme.palette.text.primary
              }}
              className="divider"
            />

            <Link
              variant="link"
              color={theme.palette.primary.main}
              className="change-location"
              onClick={() => dispatch(resetCoordinates())}
            >
              <Trans>pages.where.change_where</Trans>
            </Link>
          </>
        </Then>
      </If>
    </div>
  )
})
