import { UnifiedRecap } from '@component/Recaps/UnifiedRecap'
import { AppointmentData } from '@interface/appointment-data'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import PersonIcon from '@mui/icons-material/Person'
import RoomIcon from '@mui/icons-material/Room'
import VisibilityIcon from '@mui/icons-material/Visibility'
import moment from 'moment'
import { memo } from 'react'

interface ServiceProps {
  type: AppointmentData
}
export const Service = memo(function Service({
  type: { serviceName: name }
}: ServiceProps) {
  return (
    <UnifiedRecap
      stepTitle="pages.recap.appuntamenti"
      title={[name]}
      icon={<VisibilityIcon style={{ marginTop: '2px' }} color="primary" />}
    />
  )
})

interface WhereProps {
  where: AppointmentData
}
export const Where = memo(function Where({
  where: {
    pointOfSaleAddress: address,
    pointOfSaleCity: city,
    pointOfSaleEmail: email,
    pointOfSaleTelephone: telephone,
    pointOfSaleName: name
  }
}: WhereProps) {
  const description: Array<{
    text: string
    link?: string
    adorment?: string
  }> = [{ text: `${address} - ${city}` }]

  if (email !== undefined && email !== '') {
    description.push({
      text: email,
      link: `mailto:${email ?? ''}`,
      adorment: 'Email: '
    })
  }
  if (telephone !== undefined && telephone !== '') {
    description.push({
      text: telephone,
      link: `tel:${telephone ?? ''}`,
      adorment: 'Tel: '
    })
  }
  return (
    <UnifiedRecap
      stepTitle="pages.recap.dove"
      title={[name]}
      description={description}
      icon={<RoomIcon style={{ marginTop: '2px' }} color="primary" />}
    />
  )
})

interface WhenProps {
  appointmentSlot: AppointmentData
  children?: JSX.Element
}
export const When = memo(function When({
  appointmentSlot: { startTime: date, userName: name, userSurname: surname },
  children
}: WhenProps) {
  const description: Array<{
    text: string
    link?: string
  }> = []

  if (name !== undefined && surname !== undefined) {
    description.push({ text: `Con ${name ?? ''} ${surname ?? ''}` })
  }
  const temp = moment(date, 'YYYY-MM-DDTHH:mm')

  return (
    <UnifiedRecap
      stepTitle="pages.recap.quando"
      title={[
        `${temp.format('DD MMMM YYYY')} alle ore ${temp.format('HH:mm')}`
      ]}
      description={description}
      icon={<CalendarMonthIcon style={{ marginTop: '2px' }} color="primary" />}
    >
      {children}
    </UnifiedRecap>
  )
})

interface ImieiDatiProps {
  personalData: AppointmentData
}
export const ImieiDati = memo(function ImieiDati({
  personalData: {
    name,
    surname,
    email,
    telephone: phone,
    description: otherInfo
  }
}: ImieiDatiProps) {
  const titles: string[] = []
  const description: Array<{
    text: string
    link?: string
  }> = []

  titles.push(`${name} ${surname}`)

  description.push({
    text: `Email: ${email ?? ''}`
  })
  description.push({ text: `Tel: ${phone ?? ''}` })

  if (otherInfo !== undefined && otherInfo !== '') {
    description.push({ text: `Note: ${otherInfo ?? ''}` })
  }

  return (
    <UnifiedRecap
      stepTitle="pages.recap.i-miei-dati"
      title={titles ?? []}
      description={description ?? []}
      icon={<PersonIcon style={{ marginTop: '2px' }} color="primary" />}
    />
  )
})
