/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/indent */

import { JSXElementConstructor, ReactElement } from 'react'
import { useRoutes } from 'react-router-dom'

import { routes } from './routes'

export const AppRoutes = (): ReactElement<
  any,
  string | JSXElementConstructor<any>
> | null => {
  return useRoutes(routes)
}
