import './point-of-sale.css'

import { PointOfSale } from '@interface/point-of-sale'
import { getClient } from '@lib/info'
import { Button, useTheme } from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
import { resetWhen } from '@store/appointment-slot'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import {
  pointOfSaleDisplayed,
  selectedPointOfSale,
  setPointOfSale,
  setPointOfSaleDisplayed
} from '@store/point-of-sale'
import { resetUser } from '@store/user'
import { memo, useCallback, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { If, Then } from 'react-if'

import { PointOfSaleContainer } from './point-of-sale'

interface ListPointOfSalesProps {
  pointOfSales: PointOfSale[]
}

export const ListPointOfSales = memo(function ListPointOfSales({
  pointOfSales
}: ListPointOfSalesProps): JSX.Element {
  const selected = useAppSelector(selectedPointOfSale)
  const dispatch = useAppDispatch()
  const { numListPointOfSales }: { numListPointOfSales: number } = getClient()
  // const [seeOther, setOther] = useState<number>(numListPointOfSales)

  const seeOther = useAppSelector(pointOfSaleDisplayed)

  const handleSelected = useCallback((sale: PointOfSale) => {
    dispatch(setPointOfSale(sale))
    dispatch(resetWhen())
    dispatch(resetUser())
  }, [])

  const PointOfSales = useMemo(() => {
    return pointOfSales
      .slice(0, seeOther)
      .map((pointOfSale: PointOfSale, index) => {
        return (
          <PointOfSaleContainer
            key={nanoid()}
            pointOfSale={pointOfSale}
            selected={selected.id}
            change={(sale: PointOfSale) => handleSelected(sale)}
            index={index}
          />
        )
      })
  }, [pointOfSales, seeOther, selected])

  return (
    <div className="point-of-sale-container ">
      {PointOfSales}
      <If condition={seeOther < pointOfSales.length}>
        <Then>
          <SeeOther
            setNumber={() =>
              dispatch(setPointOfSaleDisplayed(seeOther + numListPointOfSales))
            }
          />
        </Then>
      </If>
    </div>
  )
})

interface SeeOtherProps {
  setNumber: Function
}
const SeeOther = memo(function SeeOther({
  setNumber
}: SeeOtherProps): JSX.Element {
  const { typography } = useTheme()
  return (
    <div
      style={{
        margin: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '20px'
      }}
    >
      <Button
        variant="contained"
        style={{
          ...typography?.contentDescription2,
          width: 'fit-content',
          fontWeight: 700
        }}
        color="primary"
        onClick={(event) => {
          event.stopPropagation()
          setNumber()
        }}
      >
        <Trans>pages.where.see_other</Trans>
      </Button>
    </div>
  )
})
