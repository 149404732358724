import { PointOfSale } from '@interface/point-of-sale'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '.'

const initialState: PointOfSale[] = []

export const PointOfSaleSlice = createSlice({
  name: 'PointOfSaleList',
  initialState,
  reducers: {
    addInPointOfSaleList: (state, action: PayloadAction<PointOfSale[]>) => {
      state.push(...action.payload)
    },
    resetPointOfSaleList: () => []
  }
})

export const { addInPointOfSaleList, resetPointOfSaleList } =
  PointOfSaleSlice.actions

export const selectedPointOfSales = (state: RootState): PointOfSale[] =>
  state.pointOfSaleList

// eslint-disable-next-line import/no-default-export
export default PointOfSaleSlice.reducer
