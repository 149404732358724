export const getRGB = (c: any): number[] => {
  let color = c
  let r, g, b
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }
  //   color = parseInt(color.substring(1), 16)
  //    r = color >> 16
  //    g = (color - (r << 16)) >> 8
  //    b = color - (r << 16) - (g << 8)
  return [r, g, b]
}
export const isSimilar = (
  [r1, g1, b1]: number[],
  [r2, g2, b2]: number[]
): boolean => {
  console.log('A', Math.abs(r1 - r2) + Math.abs(g1 - g2) + Math.abs(b1 - b2))
  return Math.abs(r1 - r2) + Math.abs(g1 - g2) + Math.abs(b1 - b2) < 150
}
