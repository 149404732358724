/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { client } from '@lib/client'
import { TOKEN } from '@lib/info'

export const getConfiguration = async (): Promise<any> => {
  try {
    const {
      data: { data }
    } = await client.get(`/api/ShopSign/${TOKEN() ?? ''}`)

    return data ?? []
  } catch (err) {
    console.trace(err)
    return []
  }
}
