/* eslint-disable @typescript-eslint/indent */
import { getNextStep } from '@lib/info'
import { Button, Typography, useTheme } from '@mui/material'
import { memo, useCallback, useMemo } from 'react'
import { Trans } from 'react-i18next'
import './confirm-button.css'

export interface prop {
  title?: String
  action?: Function
  disabled: boolean
  page?: string
  variant?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined
  [x: string]: any
}

export const ConfirmButton = memo(function ConfirmButton({
  title,
  action = () => {},
  disabled,
  page,
  variant = 'primary',
  ...rest
}: prop): JSX.Element {
  const theme = useTheme()

  const Wrap = ({ children }: { children: JSX.Element }): JSX.Element => {
    return (
      <div
        style={{
          position: !disabled ? 'sticky' : 'static',
          bottom: '0px'
        }}
      >
        {children}
      </div>
    )
  }

  const getTitle = useCallback(() => {
    if (page !== null && page !== undefined) {
      return `buttons.${getNextStep(page)}`
    }
    return title
  }, [page])

  const Content = useMemo(() => {
    return (
      <div
        className={'button-container padding-top-m padding-bottom-m'}
        style={{
          backgroundColor: theme.palette.background.paper
        }}
      >
        <Button
          variant="contained"
          onClick={() => action()}
          data-testid="confirm-button"
          disabled={disabled}
          color={variant}
          sx={{
            textTransform: 'unset !important',
            paddingTop: '10px',
            paddingBottom: '10px',
            margin: 'auto',
            maxWidth: '98%'
          }}
          {...rest}
        >
          <div className="insideButton">
            <Typography variant="button">
              <Trans>{getTitle()}</Trans>
            </Typography>
          </div>
        </Button>
      </div>
    )
  }, [disabled])

  return <Wrap>{Content}</Wrap>
})
