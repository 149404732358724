import { Typography } from '@mui/material'
import './not-found.css'

export const NotFound = (): JSX.Element => {
  return (
    <div className="boxNotFound">
      <div className="containerNotFound">
        <Typography variant="pageTitle">404 Not Found</Typography>
      </div>
    </div>
  )
}
