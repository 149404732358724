/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { client } from '@lib/client'
import { TOKEN } from '@lib/info'
import { store } from '@store/index'
import { useQuery } from '@tanstack/react-query'
import { getWhen } from '@utils/appointment/when'
import moment from 'moment'

export const appointmentSlotDetails = async (
  isUserSelectable: boolean,
  serviceId: string,
  pointOfSaleId: string,
  date?: string,
  userId?: string
): Promise<string[]> => {
  try {
    const { data } = await client.get(
      `/api/AppointmentSlot/Details/${TOKEN() ?? ''}`,
      {
        params: {
          serviceId,
          pointOfSaleId,
          userId: isUserSelectable ? userId : undefined,
          date
        }
      }
    )
    if ('data' in data) {
      return data.data.times.map((hour: string) => hour.slice(0, -3)).flat()
    }
    return []
  } catch (err) {
    console.trace(err)
    return []
  }
}

export const usAppointmentSlotDetails = (
  isUserSelectable: boolean,
  serviceId: string,
  PointOfSaleId: string,
  Date?: string,
  UserId?: string
) => {
  return useQuery({
    queryFn: async () =>
      await appointmentSlotDetails(
        isUserSelectable,
        serviceId,
        PointOfSaleId,
        Date,
        UserId
      ),
    queryKey: [
      'appointment-slot-details',
      Date,
      serviceId,
      PointOfSaleId,
      UserId
    ],
    retry: 2,
    retryDelay: 2000,
    select: (data: string[]) =>
      normalize(isUserSelectable, data, Date, serviceId, PointOfSaleId, UserId)
    // select: normalize
  })
}

const normalize = (
  isUserSelectable: boolean,
  data: string[],
  Date?: string,
  serviceId?: string,
  PointOfSaleId?: string,
  UserId?: string
): string[] => {
  const {
    page: { appointmentId }
  } = store.getState()

  const when = getWhen()

  const start = moment(Date, 'YYYYY/MM/DD')
  if (
    appointmentId !== undefined &&
    when?.hour !== undefined &&
    when?.date !== undefined &&
    when?.pointOfSaleId === PointOfSaleId &&
    when?.serviceId === serviceId &&
    ((isUserSelectable && when?.user === UserId) || !isUserSelectable)
  ) {
    const appointmentDate = moment(when.date, 'YYYYY/MM/DD')
    if (start.isSame(appointmentDate)) {
      const find = data.find((x) => x === when.hour)
      if (find === undefined) {
        data.push(when.hour)
        data = data.sort()
      }
    }
  }
  return data
}
