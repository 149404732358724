/* eslint-disable @typescript-eslint/no-unused-vars */
import { PointOfSale, OpenHours } from '@interface/point-of-sale'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import { Typography, Divider, Link } from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
// import { nanoid } from '@reduxjs/toolkit'
import moment from 'moment'
import { memo, useCallback, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { If, Then } from 'react-if'

interface PointOfSaleInfoProps {
  pointOfSale: PointOfSale
}
export const PointOfSaleInfo = memo(function PointOfSaleInfo({
  pointOfSale: { telephone, email, openHours, calendarDays }
}: PointOfSaleInfoProps): JSX.Element {
  const getText1 = useCallback(
    (hours: {
      startTime1?: string
      endTime1?: string
      startTime2?: string
      endTime2?: string
    }) => {
      if (hours?.startTime1 === undefined && hours?.endTime1 === undefined) {
        return 'CHIUSO'
      } else {
        return `${(hours?.startTime1 ?? '').slice(0, -3)} - ${(
          hours?.endTime1 ?? ''
        ).slice(0, -3)}`
      }
    },
    [openHours]
  )

  const getText2 = useCallback(
    (hours: {
      startTime1?: string
      endTime1?: string
      startTime2?: string
      endTime2?: string
    }) => {
      if (hours?.startTime2 === undefined && hours?.endTime2 === undefined) {
        return 'Chiuso'
      } else {
        return `${(hours?.startTime2 ?? '').slice(0, -3)} - ${(
          hours?.endTime2 ?? ''
        ).slice(0, -3)}`
      }
    },
    [openHours]
  )

  const Hours = useMemo(() => {
    const daysOfWeek = moment().day()

    const todayOrder = moment().day()
    const orderDays = []
    let i = 0
    let order = todayOrder
    while (i < 7) {
      orderDays.push(order)
      if (order === 6) {
        order = 0
      } else {
        order++
      }
      i++
    }

    return (
      <div className="hour-info-container">
        {orderDays.map((key) => {
          let day = -1
          let hours: {
            startTime1?: string
            endTime1?: string
            startTime2?: string
            endTime2?: string
          } = {}
          if (openHours !== undefined && key in openHours) {
            day = openHours[key as unknown as keyof OpenHours].day
            hours = openHours[key as unknown as keyof OpenHours].hours
          }
          return (
            <>
              <div className="hour-line-title" key={nanoid()}>
                <Typography
                  variant="contentDescription2"
                  fontWeight={day === daysOfWeek ? '900' : '400'}
                >
                  <Trans>{`DaysOfWeek.${day}`}</Trans>
                </Typography>
              </div>
              <div className="hour-line-container-info" key={nanoid()}>
                <div className="hour-slot">
                  <Typography
                    variant="contentDescription2"
                    fontWeight={day === daysOfWeek ? '900' : '400'}
                    sx={{ m: 0 }}
                  >
                    {getText1(hours)}
                  </Typography>
                </div>
                <If
                  condition={
                    hours?.startTime2 !== undefined &&
                    hours?.endTime2 !== undefined &&
                    hours?.startTime2 !== null &&
                    hours?.endTime2 !== null
                  }
                >
                  <Then>
                    <>
                      <div>
                        <Typography
                          variant="contentDescription2"
                          className="text-hour-content-bar"
                          fontWeight={day === daysOfWeek ? '900' : '400'}
                          sx={{ m: 0 }}
                        >
                          |
                        </Typography>
                      </div>
                      <div className="hour-slot">
                        <Typography
                          variant="contentDescription2"
                          fontWeight={day === daysOfWeek ? '900' : '400'}
                          sx={{ m: 0 }}
                        >
                          {getText2(hours)}
                        </Typography>
                      </div>
                    </>
                  </Then>
                </If>
              </div>
            </>
          )
        })}
      </div>
    )
  }, [openHours])

  return (
    <div key={nanoid()}>
      <Divider />
      <div
        className="point-of-sale"
        style={{ padding: '10px 20px 0 20px' }}
        key={nanoid()}
      >
        <LineDisposition
          key={nanoid()}
          condition={telephone !== undefined && telephone !== ''}
          icon={<PhoneIcon color="primary" />}
        >
          <Link
            variant="contentDescription1"
            color="primary"
            className="pos-text-inner"
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            href={`tel:${telephone}`}
            onClick={(event) => {
              event.stopPropagation()
            }}
            // fontWeight="500"
          >
            {telephone}
          </Link>
        </LineDisposition>
        <LineDisposition
          key={nanoid()}
          condition={email !== undefined && email !== ''}
          icon={<EmailIcon color="primary" />}
        >
          <Link
            variant="contentDescription1"
            color="primary"
            className="pos-text-inner"
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            href={`mailto:${email}`}
            // fontWeight="500"
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            {email}
          </Link>
        </LineDisposition>
        <LineDisposition
          key={nanoid()}
          condition={calendarDays !== undefined && calendarDays.length > 0}
          icon={<QueryBuilderIcon color="primary" />}
        >
          {Hours}
        </LineDisposition>
      </div>
    </div>
  )
})

interface LineDispositionProps {
  icon: JSX.Element
  condition?: boolean
  children: JSX.Element
}
const LineDisposition = memo(function LineDisposition({
  icon,
  condition = true,
  children
}: LineDispositionProps) {
  return (
    <If condition={condition}>
      <Then>
        <div className="pos-description-inner" key={nanoid()}>
          {icon}
          <div className="pos-text">{children}</div>
        </div>
      </Then>
    </If>
  )
})
