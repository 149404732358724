import { getClient } from '@lib/info'
import { Typography } from '@mui/material'
import ReactHtmlParser from 'react-html-parser'

export const Terms = (): JSX.Element => {
  const client = getClient()

  return (
    <>
      <Typography variant="pageTitle">
        Informativa sul trattamento dei dati personali
      </Typography>
      <div className="body padding-top-m padding-bottom-l" id="body">
        <div className="default-page" style={{ textAlign: 'left' }}>
          {ReactHtmlParser(client?.company?.privacyNotice ?? '<div></div>')}
        </div>
      </div>
    </>
  )
}
