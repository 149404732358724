import { responsiveFontSizes } from '@mui/material'
import createTheme, { Theme } from '@mui/material/styles/createTheme'

import { Info } from '../../interface'
import './fonts'

let CLIENT: Info = {
  iFrame: false,
  isModify: false,
  init: true,
  token: '',
  numListPointOfSales: 3,
  steps: [
    'appuntamenti',
    'dove',
    'quando',
    'i-miei-dati',
    'riepilogo',
    'conferma'
  ],

  personalDataUrlParams: {
    name: false,
    surname: false,
    email: false,
    phone: false,
    otherInfo: false
  },

  company: {
    logo: '',
    name: 'Ottica Blu',
    address: 'via di Fantasia, 10',
    postalCode: '31030',
    city: 'Treviso',
    region: 'TV',
    vatCode: 'IT0000000000000',
    telephone: '+390422445442',
    fax: '+390422699318',
    email: 'info@bludata.com',
    pec: 'bludata@legalmail.it',
    cookiePolicy: 'url',
    privacyPolicy: 'url',
    numberPointOfSales: 2,
    isGoogleMapsActive: true,
    isGoogleMapsSearchActive: true,
    sameDayAppointment: true,
    autoConfirmation: true,
    MinCancellationValue: 24,
    maxBookingValue: 30,
    isUserSelectable: true,
    appointmentProcess: 0,
    whatsappNumber: null
  },
  customTheme: {
    rectangle: { borderRadius: 8, borderWidth: 1, borderWidthEnable: 2 },
    logo: {}
  }
}

export const setClient = (client: Info): void => {
  CLIENT = client
}

export const getClient = (): Info => {
  return CLIENT
}

export const getTheme = (): Theme => {
  const themeDef = createTheme(CLIENT.theme)
  return responsiveFontSizes(themeDef)
}

export const getUrl = (path: string): string => {
  return `/${process.env.REACT_APP_INITIAL_PATH ?? ''}${path}/${
    CLIENT.token ?? ''
  }`
}

export const getInit = (): boolean => CLIENT?.init ?? false
export const setInit = (value: boolean): void => {
  CLIENT.init = value
}

export const TOKEN = (): string | null => CLIENT.token?.substring(1) ?? ''

export const getIndexOfStep = (name: string): number =>
  CLIENT.steps.indexOf(name)

export const getStepFromIndex = (index: number): string => CLIENT.steps[index]

export const isLastStep = (name: string): boolean => {
  const index = CLIENT.steps.indexOf(name)
  return index === CLIENT.steps.length - 1
}

export const lastStepIndex = (): number => {
  return CLIENT.steps.length - 1
}

export const isFirstStep = (name: string): boolean => {
  const index = CLIENT.steps.indexOf(name)
  return index === 0
}

export const getNextStep = (name: string): string => {
  const index = CLIENT.steps.indexOf(name)
  return CLIENT.steps[index + 1]
}

export const getRecaps = (index: number): string[] => {
  if (index !== 0) {
    const steps = CLIENT.steps.slice(0, index)
    const whereIndex = CLIENT.steps.indexOf('dove')
    if (whereIndex === -1) steps.unshift('dove')

    return steps
  }
  return []
}

export const getIsModify = (): boolean => CLIENT.isModify

export const setIsModify = (value: boolean): void => {
  CLIENT.isModify = value
}
