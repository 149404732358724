import { styled } from '@mui/material/styles'

export const ImgLogo = styled('img')(
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  ({ width, height }: { width?: number; height?: number }) => {
    return {
      width,
      height,
      maxHeight: height !== undefined ? undefined : 60,
      objectFit: 'contain',
      margin: 'auto'
    }
  }
)
