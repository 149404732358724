import { SplittedRecap } from '@component/Recaps/SplittedRecap'
import { AppointmentSlot } from '@interface/appointment-slot'
import { typeOfAppointment } from '@interface/appointment-type'
import { PersonalDataInterface } from '@interface/personal-data'
import { PointOfSale } from '@interface/point-of-sale'
import { User } from '@interface/users'
import { getClient } from '@lib/info'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import PersonIcon from '@mui/icons-material/Person'
import RoomIcon from '@mui/icons-material/Room'
import VisibilityIcon from '@mui/icons-material/Visibility'
import moment from 'moment'
import { memo } from 'react'

interface ServiceProps {
  type: typeOfAppointment
  action: Function
}
export const Service = memo(function Service({
  type: { name },
  action
}: ServiceProps) {
  return (
    <SplittedRecap
      stepTitle="pages.recap.appuntamenti"
      title={[name]}
      action={() => action()}
      icon={<VisibilityIcon style={{ marginTop: '2px' }} color="primary" />}
      actionTitle="pages.recap.change"
    />
  )
})

interface WhereProps {
  where: PointOfSale
  action: Function
}
export const Where = memo(function Where({
  where: { address, city, email, telephone, name },
  action
}: WhereProps) {
  const {
    company: { pointOfSale }
  } = getClient()
  const description: Array<{
    text: string
    link?: string
    adorment?: string
  }> = [{ text: `${address} - ${city}` }]

  if (email !== undefined && email !== '') {
    description.push({
      text: email,
      link: `mailto:${email ?? ''}`,
      adorment: 'Email: '
    })
  }
  if (telephone !== undefined && telephone !== '') {
    description.push({
      text: telephone,
      link: `tel:${telephone ?? ''}`,
      adorment: 'Tel: '
    })
  }
  return (
    <SplittedRecap
      stepTitle="pages.recap.dove"
      title={[name]}
      description={description}
      action={() => action()}
      icon={<RoomIcon style={{ marginTop: '2px' }} color="primary" />}
      actionTitle={pointOfSale === undefined ? 'pages.recap.change' : ''}
    />
  )
})

interface WhenProps {
  appointmentSlot: AppointmentSlot
  user?: User
  action: Function
}
export const When = memo(function When({
  user,
  appointmentSlot: { date, hour },
  action
}: WhenProps) {
  const description: Array<{
    text: string
    link?: string
  }> = []

  if (user !== undefined) {
    description.push({ text: `Con ${user?.name ?? ''} ${user?.surname ?? ''}` })
  }
  return (
    <SplittedRecap
      stepTitle="pages.recap.quando"
      title={[
        `${moment(date, 'YYYY-MM-DD').format('DD MMMM YYYY')} alle ore ${
          hour ?? ''
        }`
      ]}
      description={description}
      action={() => action()}
      actionTitle="pages.recap.change"
      icon={<CalendarMonthIcon style={{ marginTop: '2px' }} color="primary" />}
    />
  )
})

interface ImieiDatiProps {
  personalData: PersonalDataInterface
  action: Function
}
export const ImieiDati = memo(function ImieiDati({
  personalData: { name, surname, email, phone, otherInfo },
  action
}: ImieiDatiProps) {
  const titles: string[] = []
  const description: Array<{
    text: string
    link?: string
  }> = []

  titles.push(`${name ?? ''} ${surname ?? ''}`)

  description.push({
    text: `Email: ${email ?? ''}`
  })
  description.push({ text: `Tel: ${phone ?? ''}` })

  if (otherInfo !== undefined && otherInfo !== '') {
    description.push({ text: `Note: ${otherInfo ?? ''}` })
  }

  return (
    <SplittedRecap
      stepTitle="pages.recap.i-miei-dati"
      title={titles ?? []}
      description={description ?? []}
      action={() => action()}
      icon={<PersonIcon style={{ marginTop: '2px' }} color="primary" />}
      actionTitle="pages.recap.change"
    />
  )
})
