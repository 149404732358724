import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getClient } from '../lib/info'

import { RootState } from '.'

export interface PageState {
  value: number
  percentage: number
  steps: string[]
  addingValue: number
  stepNumber: number
  arrive: number
  appointmentId?: string
  openBanner: boolean
}

const initialState: PageState = {
  value: 0,
  percentage: 10,
  steps: ['appuntamenti', 'dove', 'quando', 'i-miei-dati', 'riepilogo'],
  addingValue: 10,
  stepNumber: 5,
  arrive: 0,
  openBanner: localStorage.getItem('appointment_banner_cookie') === null
}

const getInitialValue = (): PageState => {
  const { company } = getClient()
  const cookie = localStorage.getItem('appointment_banner_cookie') === null

  if (company.numberPointOfSales === 1 && company?.pointOfSale !== undefined) {
    return {
      value: 0,
      percentage: 12.5,
      steps: ['appuntamenti', 'quando', 'i-miei-dati', 'riepilogo'],
      addingValue: 12.5,
      stepNumber: 4,
      arrive: 0,
      openBanner: cookie
    }
  }
  if (company.appointmentProcess === 1) {
    return {
      value: 0,
      percentage: 10,
      steps: ['dove', 'appuntamenti', 'quando', 'i-miei-dati', 'riepilogo'],
      addingValue: 10,
      stepNumber: 5,
      arrive: 0,
      openBanner: cookie
    }
  }
  return {
    value: 0,
    percentage: 10,
    steps: ['appuntamenti', 'dove', 'quando', 'i-miei-dati', 'riepilogo'],
    addingValue: 10,
    stepNumber: 5,
    arrive: 0,
    openBanner: cookie
  }
}

export const pageSlice = createSlice({
  name: 'Page',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.value = action.payload
      if (action.payload < state.stepNumber) {
        state.percentage =
          state.addingValue + (100 / state.stepNumber) * action.payload
      } else state.percentage = 100
    },
    checkPages: (state) => {
      const { company } = getClient()

      if (
        company.numberPointOfSales === 1 &&
        company?.pointOfSale !== undefined
      ) {
        return {
          ...state,
          percentage: 12.5,
          steps: ['appuntamenti', 'quando', 'i-miei-dati', 'riepilogo'],
          addingValue: 12.5,
          stepNumber: 4
        }
      }
      if (company.appointmentProcess === 1) {
        return {
          ...state,
          percentage: 10,
          steps: ['dove', 'appuntamenti', 'quando', 'i-miei-dati', 'riepilogo'],
          addingValue: 10,
          stepNumber: 5
        }
      }
      return {
        ...state,
        percentage: 10,
        steps: ['appuntamenti', 'dove', 'quando', 'i-miei-dati', 'riepilogo'],
        addingValue: 10,
        stepNumber: 5
      }
    },
    setAppointmentId: (state, action: PayloadAction<string>) => {
      state.appointmentId = action.payload
    },
    increment: (state) => {
      state.value += 1
      state.arrive = state.value
      if (state.value < state.stepNumber) {
        state.percentage = state.percentage + 100 / state.stepNumber
      } else state.percentage = 100
    },
    returnBack: (state) => {
      state.value = state.arrive
      if (state.value < state.stepNumber) {
        state.percentage = state.percentage + 100 / state.stepNumber
      } else state.percentage = 100
    },
    resetPage: () => getInitialValue(),
    resetAppointmentId: (state) => {
      state.appointmentId = undefined
    },
    setOpenBanner: (state, action: PayloadAction<boolean>) => {
      state.openBanner = action.payload
    }
  }
})

export const {
  resetPage,
  checkPages,
  setPage,
  increment,
  returnBack,
  setAppointmentId,
  resetAppointmentId,
  setOpenBanner
} = pageSlice.actions

export const selectedPage = (state: RootState): number => state.page.value
export const arrived = (state: RootState): number => state.page.arrive
export const selectedPercentage = (state: RootState): number =>
  state.page.percentage
export const pageValue = (state: RootState): PageState => state.page
export const isChanging = (state: RootState): boolean =>
  state.page.value < state.page.arrive
export const getAppointmentId = (state: RootState): string | undefined =>
  state.page.appointmentId

// eslint-disable-next-line import/no-default-export
export default pageSlice.reducer
