import { PointOfSale } from '@interface/point-of-sale'
import { getClient } from '@lib/info'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '.'

interface PointOfSaleSliceInterface {
  activePoint: PointOfSale
  activeMarker: string
  studioId?: string
  customPointOfSaleId?: string
  customFilterPointOfSaleList?: string[]
  pointOfSaleDisplated: number
}

const { numListPointOfSales }: { numListPointOfSales: number } = getClient()

const initialState: PointOfSaleSliceInterface = {
  activePoint: {
    id: '',
    companyId: '',
    name: '',
    email: '',
    telephone: '',
    description: '',
    address: '',
    region: '',
    city: '',
    fax: '',
    postalCode: '',
    distance: 0,
    latitude: 0,
    longitude: 0
  },
  activeMarker: '',
  pointOfSaleDisplated: numListPointOfSales
}

const getInitialState = (): PointOfSaleSliceInterface => {
  const { company } = getClient()
  let result: PointOfSaleSliceInterface = {
    activePoint: {
      id: '',
      companyId: '',
      name: '',
      email: '',
      telephone: '',
      description: '',
      address: '',
      region: '',
      city: '',
      fax: '',
      postalCode: '',
      distance: 0,
      latitude: 0,
      longitude: 0
    },
    activeMarker: '',
    pointOfSaleDisplated: numListPointOfSales
  }
  if (company?.pointOfSale !== undefined) {
    result = {
      activePoint: company.pointOfSale,
      activeMarker: company.pointOfSale.id,
      pointOfSaleDisplated: numListPointOfSales
    }
  }
  return result
}

export const PointOfSaleSlice = createSlice({
  name: 'PointOfSale',
  initialState,
  reducers: {
    setPointOfSale: (state, action: PayloadAction<PointOfSale>) => {
      state.activePoint = action.payload
      state.activeMarker = action.payload.id
      state.studioId = undefined
    },
    setPointOfSaleFromCustomPointOfSaleId: (
      state,
      action: PayloadAction<PointOfSale>
    ) => {
      state.activePoint = action.payload
      state.activeMarker = action.payload.id
      state.studioId = undefined
      state.customPointOfSaleId = undefined
    },
    setPointOfSaleStudio: (
      state,
      action: PayloadAction<{
        activePoint: PointOfSale
        studioId?: string
      }>
    ) => {
      state.activePoint = action.payload.activePoint
      state.activeMarker = action.payload.activePoint.id
      state.studioId = action.payload.studioId
    },
    setMarker: (state, action: PayloadAction<string>) => {
      state.activeMarker = action.payload
    },
    setCustomPointOfSaleId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.customPointOfSaleId = action.payload
    },
    setFilterPointOfSaleList: (
      state,
      action: PayloadAction<string[] | undefined>
    ) => {
      state.customFilterPointOfSaleList = action.payload
    },
    checkPointOfSale: (state) => {
      const { company, numListPointOfSales } = getClient()
      if (company?.pointOfSale !== undefined) {
        return {
          activePoint: company.pointOfSale,
          activeMarker: company.pointOfSale.id,
          pointOfSaleDisplated: state.pointOfSaleDisplated
        }
      }
      return {
        activePoint: {
          id: '',
          companyId: '',
          name: '',
          email: '',
          telephone: '',
          description: '',
          address: '',
          region: '',
          city: '',
          fax: '',
          postalCode: '',
          distance: 0,
          latitude: 0,
          longitude: 0
        },
        activeMarker: '',
        pointOfSaleDisplated: numListPointOfSales
      }
    },
    resetCustomPointOfSaleId: (state) => {
      state.customPointOfSaleId = undefined
    },
    resetFilterCustomPointOfSaleList: (state) => {
      state.customFilterPointOfSaleList = undefined
    },
    setPointOfSaleDisplayed: (state, action: PayloadAction<number>) => {
      state.pointOfSaleDisplated = action.payload
    },
    resetWhere: () => getInitialState()
  }
})

export const {
  resetWhere,
  setPointOfSale,
  setPointOfSaleStudio,
  setCustomPointOfSaleId,
  resetCustomPointOfSaleId,
  setPointOfSaleFromCustomPointOfSaleId,
  resetFilterCustomPointOfSaleList,
  setFilterPointOfSaleList,
  setPointOfSaleDisplayed
} = PointOfSaleSlice.actions

export const selectedPointOfSale = (state: RootState): PointOfSale =>
  state.pointOfSale.activePoint

export const selectedStudio = (state: RootState): string | undefined =>
  state.pointOfSale?.studioId

export const selectedMarker = (state: RootState): string =>
  state.pointOfSale.activeMarker

export const selectedCustomPointOfSaleId = (
  state: RootState
): string | undefined => state.pointOfSale.customPointOfSaleId

export const pointOfSaleDisplayed = (state: RootState): number =>
  state.pointOfSale.pointOfSaleDisplated

export const selectedFilterCustomPointOfSaleList = (
  state: RootState
): string[] | undefined => state.pointOfSale.customFilterPointOfSaleList

// eslint-disable-next-line import/no-default-export
export default PointOfSaleSlice.reducer
