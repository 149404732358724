import { NetworkMode, QueryClient } from '@tanstack/react-query'

const defaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    // refetchOnMount: false
    networkMode: 'always' as NetworkMode
  }
}

export const queryClient = new QueryClient({
  defaultOptions
})
