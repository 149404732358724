import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CoordsInterface } from '../interface/coordinates'

import { RootState } from '.'

interface CoordinatesInterface {
  coords?: CoordsInterface
  loading: boolean
  myPosition: boolean
  text: string
  initial: boolean
}

const initialState: CoordinatesInterface = {
  coords: undefined,
  loading: false,
  myPosition: false,
  text: '',
  initial: true
}

export const CoordinateSlice = createSlice({
  name: 'Coordinates',
  initialState,
  reducers: {
    setCoordinates: (state, action: PayloadAction<CoordsInterface>) => {
      state.coords = {
        lat: action.payload.lat,
        lng: action.payload.lng
      }
      state.loading = false
      state.initial = false
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.text = action.payload
    },
    setLoading: (state) => {
      state.loading = !state.loading
    },
    setInitial: (state) => {
      state.initial = !state.initial
    },
    setMyPosition: (state, action: PayloadAction<boolean>) => {
      state.myPosition = action.payload
    },
    resetCoordinates: () => initialState
  }
})

export const {
  setInitial,
  setCoordinates,
  setLoading,
  setMyPosition,
  resetCoordinates,
  setAddress
} = CoordinateSlice.actions

export const selectedCoords = (state: RootState): CoordsInterface | undefined =>
  state.coordinates?.coords

export const coordsLoading = (state: RootState): boolean =>
  state.coordinates.loading

export const selectedCoordinates = (state: RootState): CoordinatesInterface =>
  state.coordinates

// eslint-disable-next-line import/no-default-export
export default CoordinateSlice.reducer
