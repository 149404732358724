import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import it from './translations/it.json'

export const resources = {
  it: {
    translation: it
  }
}

export const defaultNS = 'translation'

i18n
  .use(initReactI18next)
  .init({
    resources,
    ns: ['translation'],
    fallbackLng: 'it',
    defaultNS,
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })
  .catch(() => {})

// eslint-disable-next-line
export default i18n
