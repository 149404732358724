/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/indent */
import { useAppSelector } from '@store/hooks'
import { selectedPage } from '@store/page'
import { getAppointment } from '@utils/appointment'
import { FC } from 'react'
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'

import { getIndexOfStep, getStepFromIndex } from '../info'

export const CheckPreviousStepCompletion = ({
  name,
  customPath = false
}: {
  name: string
  customPath?: boolean
}): JSX.Element => {
  const step = getIndexOfStep(name)
  const page = useAppSelector(selectedPage)
  const param = useParams()
  const { search } = useLocation()

  if (page < step) {
    if (customPath) {
      return (
        <Navigate
          to={`/${
            process.env.REACT_APP_INITIAL_PATH ?? ''
          }custom/${getStepFromIndex(page)}/${param.token ?? ''}${search}`}
        />
      )
    }
    return (
      <Navigate
        to={`/${process.env.REACT_APP_INITIAL_PATH ?? ''}${getStepFromIndex(
          page
        )}/${param.token ?? ''}`}
      />
    )
  }

  return <Outlet />
}

export const CheckConferma: FC<{ customPath?: boolean }> = ({
  customPath = false
}): JSX.Element => {
  const appointment = getAppointment()
  const { search } = useLocation()

  if (appointment !== undefined) {
    return <Outlet />
  }
  const page = useAppSelector(selectedPage)
  const param = useParams()
  if (customPath) {
    return (
      <Navigate
        to={`/${
          process.env.REACT_APP_INITIAL_PATH ?? ''
        }custom/${getStepFromIndex(page)}/${param.token ?? ''}${search}`}
      />
    )
  }
  return (
    <Navigate
      to={`/${process.env.REACT_APP_INITIAL_PATH ?? ''}${getStepFromIndex(
        page
      )}/${param.token ?? ''}`}
    />
  )
}
