export const MetaTheme = (color: string): void => {
  try {
    const meta = document.querySelector('meta[name="theme-color"]')
    if (meta !== null) {
      meta.setAttribute('content', color)
    }
  } catch (error) {
    console.error(error)
  }
}
