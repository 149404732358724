import { CircularProgress, Modal } from '@mui/material'
import { memo } from 'react'

export const Mask = memo(function Mask(): JSX.Element {
  return (
    <Modal
      open={true}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <CircularProgress
        size="170px"
        thickness={2.3}
        style={{ margin: 'auto' }}
      />
    </Modal>
  )
})
