/* eslint-disable @typescript-eslint/no-unused-vars */
import './appuntamenti.css'

import { getTypeOfAppointment } from '@api/appointment-types'
import { ConfirmButton } from '@component/confirmButton'
import { PageTitle } from '@component/PageTitle'
import { Recaps } from '@component/Recaps/DefaultRecap'
import { Rectangle } from '@component/rectangle'
import { typeOfAppointment } from '@interface/appointment-type'
import { getIndexOfStep, isFirstStep } from '@lib/info'
import { CircularProgress, Typography } from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
import { resetWhen } from '@store/appointment-slot'
import {
  resetType,
  selectedCustomServiceId,
  selectedType,
  setType,
  setTypeFromCustomServiceId
} from '@store/appointment-type'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { increment } from '@store/page'
import { selectedPointOfSale } from '@store/point-of-sale'
import { resetUser } from '@store/user'
import { memo, useCallback, useDeferredValue, useEffect, useMemo } from 'react'
import { Else, If, Then } from 'react-if'

export const Appuntamenti = memo(function Appuntamenti(): JSX.Element {
  const { id: type } = useAppSelector(selectedType)
  const customServiceId = useAppSelector(selectedCustomServiceId)
  const where = useAppSelector(selectedPointOfSale)

  const dispatch = useAppDispatch()

  // ? CALL
  const { data = [], isLoading } = getTypeOfAppointment(
    where.id,
    isFirstStep('appuntamenti')
  )
  const deferredValue = useDeferredValue(data)

  const handleClick = useCallback(() => {
    dispatch(increment())
  }, [])

  useEffect(() => {
    if (deferredValue !== undefined && deferredValue.length !== 0) {
      if (customServiceId !== undefined) {
        const find = deferredValue.find((x) => x.id === customServiceId)
        if (find !== undefined) {
          dispatch(setTypeFromCustomServiceId(find as any))
        }
      }
      if (type !== '') {
        const find = deferredValue.find((x) => x.id === type)
        if (find === undefined) {
          dispatch(resetType())
          dispatch(resetWhen())
        }
      }
    }
  }, [deferredValue])

  const TypesList = useMemo(() => {
    return deferredValue.map((service: typeOfAppointment) => (
      <div key={nanoid()} className="margin-top-xs margin-bottom-xs">
        <Rectangle
          action={() => {
            dispatch(setType(service))
            dispatch(resetWhen())
            dispatch(resetUser())
          }}
          active={service.id === type}
        >
          <div className="textContainer">
            <div className="text">
              <Typography variant="contentTitle1">{service.name}</Typography>
              <Typography variant="contentDescription1">
                {service.description}
              </Typography>
            </div>
          </div>
        </Rectangle>
      </div>
    ))
  }, [deferredValue, type])

  return (
    <div className="default-page">
      <Recaps index={getIndexOfStep('appuntamenti')} />
      <div className="padding-top-s padding-bottom-xm">
        <PageTitle title="pages.appointment.title" />
      </div>
      <div className="content">
        <If condition={isLoading}>
          <Then>
            <CostumLoader />
          </Then>
          <Else>{TypesList}</Else>
        </If>
      </div>

      <ConfirmButton
        page="appuntamenti"
        disabled={type === ''}
        action={() => handleClick()}
      />
    </div>
  )
})

const CostumLoader = memo(function costumLoader(): JSX.Element {
  return (
    <div className="margin-top-xs margin-bottom-xs">
      <CircularProgress size={'195px'} thickness={2.3} />
    </div>
  )
})
