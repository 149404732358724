import './banner.css'

import { Button, Typography, useTheme } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { pageValue, setOpenBanner } from '@store/page'
import { getRGB, isSimilar } from '@utils/manage-color'
import { memo, useCallback } from 'react'
import { Trans } from 'react-i18next'

export const BannerCookie = memo(function BannerCookie(): JSX.Element {
  const page = useAppSelector(pageValue)
  const dispatch = useAppDispatch()
  const {
    typography: { contentDescription2 },
    components
  } = useTheme()

  const handleClick = useCallback((value: boolean) => {
    dispatch(setOpenBanner(false))
    localStorage.setItem('appointment_banner_cookie', value.toString())
  }, [])

  return (
    <div
      className="banner-container  padding-top-m padding-bottom-m"
      style={{ display: page.openBanner ? 'flex' : 'none' }}
    >
      <div className="text">
        <Typography variant="contentDescription2" sx={{ color: 'white' }}>
          <Trans>banner.content1</Trans>
        </Typography>
        <Typography variant="contentDescription2" sx={{ color: 'white' }}>
          <Trans>banner.content2</Trans>
        </Typography>
        <Typography variant="contentDescription2" sx={{ color: 'white' }}>
          <Trans>banner.content3</Trans>
        </Typography>
        <Typography variant="contentDescription2" sx={{ color: 'white' }}>
          <Trans>banner.content4</Trans>
        </Typography>
      </div>
      <div className="buttons">
        <Button
          style={{
            ...contentDescription2,
            marginBottom: '5px'
          }}
          sx={{
            color: isSimilar(
              [0, 0, 0],
              getRGB(
                (components?.MuiButton?.styleOverrides?.root as any)?.color ??
                  '#ffffff'
              )
            )
              ? 'white'
              : (components?.MuiButton?.styleOverrides?.root as any)?.color ??
                '#ffffff'
          }}
          onClick={() => handleClick(true)}
        >
          <Trans>banner.confirm_button</Trans>
        </Button>
        <Button
          onClick={() => handleClick(false)}
          style={{ ...contentDescription2 }}
          sx={{
            color: isSimilar(
              [0, 0, 0],
              getRGB(
                (components?.MuiButton?.styleOverrides?.root as any)?.color ??
                  '#ffffff'
              )
            )
              ? 'white'
              : (components?.MuiButton?.styleOverrides?.root as any)?.color ??
                '#ffffff'
          }}
        >
          <Trans>banner.reject_cookie</Trans>
        </Button>
      </div>
    </div>
  )
})
