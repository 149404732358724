/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import '../dove.css'

import { PageTitle } from '@component/PageTitle'
import { client } from '@lib/client'
import MyLocationTwoToneIcon from '@mui/icons-material/MyLocationTwoTone'
import RoomIcon from '@mui/icons-material/Room'
import SearchIcon from '@mui/icons-material/Search'
import {
  InputAdornment,
  Link,
  TextField,
  useTheme,
  MenuItem,
  CircularProgress,
  Card,
  IconButton
} from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
import { setAlert } from '@store/alert'
import {
  setAddress,
  setCoordinates,
  setInitial,
  setLoading,
  setMyPosition
} from '@store/coordinates'
import { useAppDispatch } from '@store/hooks'
import i18next from 'i18next'
import { memo, useCallback, useMemo, useState } from 'react'
import usePlacesWidget from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { Trans } from 'react-i18next'

export const SearchAddress = memo(function SearchAddress(): JSX.Element {
  const dispatch = useAppDispatch()
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    autocompleteSessionToken
  } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    debounce: 0,
    sessionToken: true,
    language: 'it',
    options: {
      input: 'string',
      componentRestrictions: { country: 'it' }
    }
  })

  const useClientPosition = useCallback((): void => {
    dispatch(setLoading())
    dispatch(setInitial())
    navigator.geolocation.getCurrentPosition(
      (position: any): void => {
        if (
          position?.coords?.latitude !== undefined &&
          position?.coords?.longitude !== undefined
        ) {
          dispatch(
            setCoordinates({
              lat: position?.coords?.latitude,
              lng: position?.coords?.longitude
            })
          )
          dispatch(setMyPosition(true))
          // dispatch(setLoading())
        }
      },
      (error: any): void => {
        console.log(error)
        dispatch(setMyPosition(true))

        dispatch(setLoading())

        // dispatch(setCoordinates({}))
      }
    )
  }, [])

  return (
    <div className="default-page padding-top-xs" style={{ minHeight: '300px' }}>
      <div className="padding-top-xs padding-bottom-xs">
        <PageTitle title="pages.where.title" />
      </div>
      <div className="padding-top-xs padding-bottom-xs">
        <AutoCompleteMaps
          placePredictions={placePredictions}
          getPlacePredictions={(x: any) => getPlacePredictions(x)}
          isPlacePredictionsLoading={isPlacePredictionsLoading}
          autocompleteSessionToken={autocompleteSessionToken}
        />
      </div>
      <Link
        variant="link"
        color="primary"
        onClick={() => useClientPosition()}
        style={{ cursor: 'pointer', textDecoration: 'none' }}
        className="my-position padding-top-xs"
      >
        <MyLocationTwoToneIcon color="primary" sx={{ fontSize: '30px' }} />
        <div className="text-position">
          <Trans>pages.where.use_position</Trans>
        </div>
      </Link>
    </div>
  )
})

interface AutoCompleteMapsProps {
  placePredictions: google.maps.places.AutocompletePrediction[]
  getPlacePredictions: Function
  isPlacePredictionsLoading: boolean
  autocompleteSessionToken?: google.maps.places.AutocompleteSessionToken
}
export const AutoCompleteMaps = memo(function AutoCompleteMaps({
  placePredictions,
  getPlacePredictions,
  isPlacePredictionsLoading,
  autocompleteSessionToken
}: AutoCompleteMapsProps): JSX.Element {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const placeholder = i18next.t('pages.where.search_placeholder')

  const [value, setValue] = useState<string>('')
  const [choose, setChoose] = useState<string>('')

  const Content = useMemo(() => {
    if (choose === '') {
      return placePredictions?.map(
        (u: google.maps.places.AutocompletePrediction) => (
          <MenuItem
            key={nanoid()}
            onClick={async () => {
              await handleClick(u.description)
            }}
          >
            {u.description}
          </MenuItem>
        )
      )
    }
    return null
  }, [placePredictions, choose])

  const handleClick = useCallback(
    async (valueClicked?: string) => {
      const val = valueClicked !== undefined ? valueClicked : value
      try {
        dispatch(setLoading())
        dispatch(setInitial())
        const {
          data: { results, status }
        } = await client.get(
          'https://maps.googleapis.com/maps/api/geocode/json',
          {
            params: {
              address: val,
              key: process.env.REACT_APP_GOOGLE_KEY,
              language: 'it'
            }
          }
        )
        if (status !== 'ZERO_RESULTS') {
          const { lat, lng } = results[0].geometry.location
          dispatch(setAddress(val))
          dispatch(
            setCoordinates({
              lat,
              lng
            })
          )
        } else {
          throw new Error('1000')
        }
      } catch (error: any) {
        // dispatch(setLoading())
        if (error.message === '1000') {
          console.log('error.message: ', error.message)
          dispatch(setInitial())
          dispatch(setLoading())
          dispatch(
            setAlert({
              type: 'warning',
              cancel: false,
              code: error.message === '1000' ? '1000' : '999'
            })
          )
        } else {
          dispatch(setLoading())
          dispatch(setMyPosition(true))
        }
        console.error(error)
      }
    },
    [value]
  )

  return (
    <div className="search-autocomplete">
      <TextField
        size="small"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value
          setValue(newValue)
          getPlacePredictions({
            input: newValue,
            sessionToken: autocompleteSessionToken
          })
          if (choose !== '') {
            setChoose('')
          }
        }}
        placeholder={placeholder}
        style={{ backgroundColor: theme.palette.card.main }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {!isPlacePredictionsLoading ? (
                <RoomIcon color="primary" sx={{ fontSize: '25px' }} />
              ) : (
                <CircularProgress size="25px" />
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{
                  width: '30px',
                  height: '30px',
                  color: value === '' ? 'none' : theme.palette.primary.main
                }}
                disabled={value === ''}
                onClick={async () => await handleClick()}
              >
                <SearchIcon sx={{ fontSize: '30px' }} />
              </IconButton>
            </InputAdornment>
          )
        }}
        fullWidth
      />
      <Card
        className="default-page"
        style={{
          backgroundColor: theme.palette.card.main,
          position: 'absolute',
          width: '100%',
          minHeight: 'fit-content'
        }}
      >
        {Content}
      </Card>
    </div>
  )
})
