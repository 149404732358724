export const resizeSteps = (
  newValue: number,
  previousValue: number
): number => {
  // return 800w.innerWidth))
  if (newValue > 800) {
    if (previousValue !== 900) {
      return 900
    }
  } else if (newValue <= 800 && newValue > 700) {
    if (previousValue !== 800) {
      return 800
    }
  } else if (newValue <= 700 && newValue > 600) {
    if (previousValue !== 700) {
      return 700
    }
  } else if (newValue <= 600 && newValue > 500) {
    if (previousValue !== 600) {
      return 600
    }
  } else if (newValue <= 500 && newValue > 400) {
    if (previousValue !== 500) {
      return 500
    }
  } else if (newValue <= 400 && newValue > 300) {
    if (previousValue !== 400) {
      return 400
    }
  } else if (newValue <= 300) {
    if (previousValue !== 300) {
      return 300
    }
  }
  return 0
  // setWidth(window.innerWidth)
}
