/* eslint-disable @typescript-eslint/no-unused-vars */
import './date-picker.css'

import { StaticRectangle } from '@component/rectangle'
import { getClient } from '@lib/info'
import { Typography } from '@mui/material'
import { selectedDate } from '@store/appointment-slot'
import { useAppSelector } from '@store/hooks'
import { responsiveWidth } from '@store/responsive'
import moment, { Moment } from 'moment'
import { memo, useCallback, useMemo, useState } from 'react'
import { If, Then } from 'react-if'

import { HourPicker } from './hour-picker'
import { WeekPicker } from './week-picker'

export const DatePicker = memo(function DatePicker(): JSX.Element {
  return (
    <StaticRectangle>
      <>
        <WeekPickerOuter />
        <DayTitle />
        <HourPicker />
      </>
    </StaticRectangle>
  )
})

const DayTitle = memo(function DayTitle(): JSX.Element {
  const selectedDay = useAppSelector(selectedDate)
  return (
    <>
      <If condition={selectedDay !== undefined}>
        <Then>
          <div className="selected-date">
            <Typography variant="contentDescription1">
              {moment(selectedDay, 'YYYY-MM-DD').format('dddd DD MMMM YYYY')}
            </Typography>
          </div>
        </Then>
      </If>
    </>
  )
})

const WeekPickerOuter = memo(function WeekPickerOuter(): JSX.Element {
  const {
    company: { maxBookingValue }
  } = getClient()
  const date = useAppSelector(selectedDate)
  const startDate =
    date === undefined ? moment().startOf('day') : moment(date).startOf('day')
  const maxDay = moment().startOf('day').add(maxBookingValue, 'hours')

  const [dates, setDates] = useState<Moment>(startDate)
  const width = useAppSelector(responsiveWidth)

  const getRange = useCallback((): number => {
    if (width > 600) return 6
    else if (width > 500) return 5
    else if (width > 400) return 4
    else if (width > 300) return 3
    else return 2
  }, [width])

  const Week = useMemo(() => {
    return (
      <WeekPicker
        startDate={dates}
        range={getRange()}
        setDates={(dates: Moment) => setDates(dates)}
        maxDay={maxDay}
      />
    )
  }, [dates, getRange])

  return <>{Week}</>
})
