/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { DateInterface } from '@interface/date'
import { client } from '@lib/client'
import { TOKEN } from '@lib/info'
import { store } from '@store/index'
import { useQuery } from '@tanstack/react-query'
import { getWhen } from '@utils/appointment/when'
import moment, { Moment } from 'moment'

export const appointmentSlot = async (
  isUserSelectable: boolean,
  serviceId: string,
  pointOfSaleId: string,
  startDate: string,
  endDate: string,
  userId?: string
): Promise<Moment[]> => {
  try {
    // TOKEN()
    const { data } = await client.get(`/api/AppointmentSlot/${TOKEN() ?? ''}`, {
      params: {
        serviceId,
        pointOfSaleId,
        userId: isUserSelectable ? userId : undefined,
        startDate,
        endDate
      }
    })
    if ('data' in data) {
      return data.data.map(({ date }: any) => moment(date, 'YYYY-MM-DD')).flat()
    }
    return []
  } catch (err) {
    console.trace(err)
    return []
  }
}

export const usAppointmentSlot = (
  isUserSelectable: boolean,
  serviceId: string,
  PointOfSaleId: string,
  StartDate: string,
  EndDate: string,
  UserId?: string
) => {
  return useQuery({
    queryFn: async () =>
      await appointmentSlot(
        isUserSelectable,
        serviceId,
        PointOfSaleId,
        StartDate,
        EndDate,
        UserId
      ),
    queryKey: [
      'appointment-slot',
      `${StartDate}-${EndDate}`,
      serviceId,
      PointOfSaleId,
      UserId
    ],
    retry: 2,
    retryDelay: 2000,
    select: (data: Moment[]) =>
      normalize(
        isUserSelectable,
        data,
        StartDate,
        EndDate,
        UserId,
        serviceId,
        PointOfSaleId
      )
  })
}

const normalize = (
  isUserSelectable: boolean,
  data: Moment[],
  startDate: string,
  endDate: string,
  userId?: string,
  serviceId?: string,
  pointOfSaleId?: string
): DateInterface => {
  const start = moment(startDate, 'YYYYY/MM/DD').clone()
  const end = moment(endDate, 'YYYY/MM/DD')

  const {
    page: { appointmentId }
  } = store.getState()

  const when = getWhen()

  let result: DateInterface = {}
  while (start.diff(end) <= 0) {
    result = {
      ...result,
      [start.format('YYYY/MM/DD')]: {
        date: start,
        daysOfWeek: start.day(),
        day: start.date(),
        active: false
      }
    }
    start.add(1, 'days')
  }

  if (
    appointmentId !== undefined &&
    when?.date !== undefined &&
    when?.pointOfSaleId === pointOfSaleId &&
    when?.serviceId === serviceId &&
    ((isUserSelectable && when?.user === userId) || !isUserSelectable)
  ) {
    const appointmentDate = moment(when.date, 'YYYYY/MM/DD')
    if (when?.date in result) {
      result[when?.date] = {
        date: appointmentDate,
        daysOfWeek: appointmentDate.day(),
        day: appointmentDate.date(),
        active: true
      }
    }
  }

  // const today = moment().startOf('day')
  for (const date of data) {
    const formattedDate = date.format('YYYY/MM/DD')
    if (formattedDate in result) {
      result[formattedDate as keyof DateInterface].active = true
    }
  }
  return result
}
