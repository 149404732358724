import { ConfirmButton } from '@component/confirmButton'
import { PageTitle } from '@component/PageTitle'
// import { PreviousSteps } from '@component/previous-steps'
import { Recaps } from '@component/Recaps/DefaultRecap'
import { getClient, getIndexOfStep } from '@lib/info'
import { selectedAppointmentSlot } from '@store/appointment-slot'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { increment } from '@store/page'
import { useCallback } from 'react'
import { If, Then } from 'react-if'

import { DatePicker } from './components/date-picker'
import { SelectUser } from './components/select-user'
import './quando.css'

export const Quando = (): JSX.Element => {
  const {
    company: { isUserSelectable }
  } = getClient()
  const appointmentSlot = useAppSelector(selectedAppointmentSlot)
  const dispatch = useAppDispatch()

  const handleClick = useCallback(() => {
    dispatch(increment())
  }, [])

  // test
  return (
    <div className="default-page">
      <Recaps index={getIndexOfStep('quando')} />
      <div className="padding-bottom-xs padding-top-xs">
        <PageTitle title="pages.when.title" />
      </div>
      <div className="padding-bottom-xm">
        <If condition={isUserSelectable}>
          <Then>
            <SelectUser />
          </Then>
        </If>
      </div>
      <div>
        <DatePicker />
      </div>
      <ConfirmButton
        title="pages.when.button"
        disabled={
          appointmentSlot?.date === undefined ||
          appointmentSlot?.hour === undefined
        }
        action={() => handleClick()}
      />
    </div>
  )
}
