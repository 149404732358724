/* eslint-disable @typescript-eslint/indent */
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import AlertReducer from './alert'
import AppointmentSlotReducer from './appointment-slot'
import typeOfAppointmentReducer from './appointment-type'
import CoordinatesReducer from './coordinates'
import PointOfSaleListReducer from './list-point-of-sale'
import pageReducer from './page'
import PersonalDataReducer from './personal-data'
import PointOfSaleReducer from './point-of-sale'
import ResponsiveReducer from './responsive'
import UserReducer from './user'

export const store = configureStore({
  reducer: {
    page: pageReducer,
    typeOfAppointment: typeOfAppointmentReducer,
    pointOfSale: PointOfSaleReducer,
    user: UserReducer,
    responsive: ResponsiveReducer,
    appointmentSlot: AppointmentSlotReducer,
    personalData: PersonalDataReducer,
    coordinates: CoordinatesReducer,
    alert: AlertReducer,
    pointOfSaleList: PointOfSaleListReducer
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
