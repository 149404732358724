import { setAlert } from '@store/alert'
import { store } from '@store/index'
import axios from 'axios'

const client = axios.create({
  // baseURL: 'https://booking.ilmioottico.it/',
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'text/plain'
  },
  timeout: 100000
})

client.interceptors.response.use(
  (res) => res,
  async (err) => {
    const data = err.response?.data

    store.dispatch(
      setAlert({
        type: 'warning',
        cancel: false,
        code: data?.code ?? '999'
      })
    )

    console.error(err)
    return await Promise.resolve([])
  }
)

export { client }
