import { getClient } from '@lib/info'
import { Typography } from '@mui/material'
import ReactHtmlParser from 'react-html-parser'

export const Privacy = (): JSX.Element => {
  const client = getClient()

  return (
    <>
      <Typography variant="pageTitle">Privacy policy</Typography>
      <div className="body padding-top-m padding-bottom-l" id="body">
        <div className="default-page" style={{ textAlign: 'left' }}>
          {ReactHtmlParser(client?.company?.privacyPolicy ?? '<div></div>')}
        </div>
      </div>
    </>
  )
}
