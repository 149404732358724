/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import './rectangle.css'
import { getClient } from '@lib/info'
import { useTheme } from '@mui/material/styles'
import { nanoid } from 'nanoid'
import { memo, useMemo } from 'react'

import { InnerBox, ParentBox, StaticInnerBox } from './style'

interface RectangleProp {
  active?: boolean
  action: Function
  children: JSX.Element
  hooverEffect?: boolean
}

export const Rectangle = memo(function Rectangle(
  props: RectangleProp
): JSX.Element {
  const { active, action, children } = props
  const theme = useTheme()

  const {
    customTheme: { rectangle }
  } = getClient()
  // const classes = useStyles()

  const innerContent = useMemo(() => {
    return <>{children}</>
  }, [children])

  const Content = useMemo(() => {
    return (
      <InnerBox {...rectangle} theme={theme} selected={active ?? false}>
        {innerContent}
      </InnerBox>
    )
  }, [innerContent, active])

  return (
    <ParentBox
      borderRadius={rectangle.borderRadius}
      key={nanoid()}
      onClick={() => action()}
    >
      {Content}
    </ParentBox>
  )
})

interface prop {
  children: JSX.Element
}

export const StaticRectangle = memo(function StaticRectangle(
  props: prop
): JSX.Element {
  const { children } = props
  const theme = useTheme()
  const {
    customTheme: { rectangle }
  } = getClient()
  return (
    <ParentBox borderRadius={rectangle.borderRadius} staticType key={nanoid()}>
      <StaticInnerBox theme={theme} borderWidth={rectangle.borderWidth}>
        {children}
      </StaticInnerBox>
    </ParentBox>
  )
})
