/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { client } from '@lib/client'
import { TOKEN } from '@lib/info'

export const deleteAppointment = async (
  appointmentId: string
): Promise<boolean> => {
  try {
    // const { data } = await client.get('/api/Service/111')
    await client.delete(`/api/Appointment/${TOKEN() ?? ''}/${appointmentId}`)

    return true
  } catch (err) {
    console.trace(err)
    return false
  }
}
