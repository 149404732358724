import { Typography } from '@mui/material'
import { memo } from 'react'
import { Trans } from 'react-i18next'
import './page-title.css'

interface PageTitleInterface {
  title: string
}

export const PageTitle = memo(function PageTitle({
  title
}: PageTitleInterface): JSX.Element {
  return (
    <div className="titlePage">
      <Typography variant="pageTitle" fontWeight={700}>
        <Trans>{title}</Trans>
      </Typography>
    </div>
  )
})
