/* eslint-disable @typescript-eslint/no-unused-vars */
import './week-picker.css'
import 'moment/locale/it'

import { usAppointmentSlot } from '@api/appointment-slot'
import { DateInterface } from '@interface/date'
import { getClient } from '@lib/info'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  IconButton,
  Skeleton,
  Theme,
  Typography,
  useTheme
} from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
import { selectedDate, setDateSlot } from '@store/appointment-slot'
import { selectedType } from '@store/appointment-type'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { selectedPointOfSale } from '@store/point-of-sale'
import { selectedUser } from '@store/user'
import moment, { Moment } from 'moment'
import {
  CSSProperties,
  memo,
  useDeferredValue,
  useEffect,
  useMemo
} from 'react'
import { Trans } from 'react-i18next'
import { Else, If, Then } from 'react-if'

interface WeekPickerProps {
  startDate: Moment
  setDates: Function
  range: number
  maxDay: Moment
}

export const WeekPicker = memo(function WeekPicker({
  startDate,
  range,
  setDates,
  maxDay
}: WeekPickerProps): JSX.Element {
  const {
    company: { isUserSelectable }
  } = getClient()
  const { id: type } = useAppSelector(selectedType)
  const { id: where } = useAppSelector(selectedPointOfSale)
  const user = useAppSelector(selectedUser)
  const dispatch = useAppDispatch()
  const selectedDay = useAppSelector(selectedDate)

  const endDate = startDate.clone().add(range, 'days')
  const { data, isLoading } = usAppointmentSlot(
    isUserSelectable,
    type,
    where,
    startDate.format('YYYY/MM/DD'),
    endDate.format('YYYY/MM/DD'),
    user?.id
  )
  const deferredValue = useDeferredValue(data)

  // ? Do call of forward week to remove on change loading
  // const followWeeekStart = startDate.clone().add(range + 1, 'days')
  // const followWeeekEnd = endDate.clone().add(range + 1, 'days')
  // usAppointmentSlot(
  //   type,
  //   where,
  //   followWeeekStart.format('YYYY/MM/DD'),
  //   followWeeekEnd.format('YYYY/MM/DD'),
  //   user?.id
  // )

  useEffect(() => {
    if (
      endDate.isBefore(maxDay) &&
      selectedDay !== undefined &&
      deferredValue !== undefined
    ) {
      if (selectedDay in deferredValue) {
        if (!deferredValue[selectedDay].active) {
          const temp = moment(selectedDay, 'YYYY/MM/DD').add(1, 'days')
          let d = temp.format('YYYY/MM/DD')
          while (d in deferredValue && !deferredValue[d].active) {
            temp.add(1, 'days')
            d = temp.format('YYYY/MM/DD')
          }

          if (!(d in deferredValue)) {
            setDates(temp)
            dispatch(setDateSlot(d))
          } else {
            dispatch(setDateSlot(d))
          }
        }
      } else {
        setDates(endDate.add(1, 'days'))
        dispatch(setDateSlot(endDate.add(1, 'days').format('YYYY/MM/DD')))
      }
    }
  }, [deferredValue])

  const Dates = useMemo((): JSX.Element => {
    if (deferredValue !== undefined) {
      return <WeekPickerInner data={deferredValue} />
    }
    return <></>
  }, [deferredValue])

  return (
    <div className="week-picker">
      <IconButton
        disabled={!moment().isBefore(startDate)}
        onClick={() => {
          const newStart = startDate.clone().subtract(range + 1, 'days')
          setDates(newStart)
          dispatch(setDateSlot(newStart.format('YYYY/MM/DD')))
        }}
        className="date-container "
      >
        <KeyboardArrowLeftIcon style={{ fontSize: '35px' }} />
      </IconButton>

      <If condition={isLoading}>
        <Then>
          <LoadingDateContainer />
        </Then>
        <Else>{Dates}</Else>
      </If>
      <IconButton
        disabled={startDate.isAfter(maxDay)}
        onClick={() => {
          const newStart = startDate.clone().add(range + 1, 'days')
          setDates(newStart)
          dispatch(setDateSlot(newStart.format('YYYY/MM/DD')))
        }}
        className="date-container "
      >
        <KeyboardArrowRightIcon style={{ fontSize: '35px' }} />
      </IconButton>
    </div>
  )
})

export const WeekPickerInner = memo(function WeekPickerInner({
  data
}: {
  data?: DateInterface
}): JSX.Element {
  const theme = useTheme()
  const selectedDay = useAppSelector(selectedDate)
  const dispatch = useAppDispatch()
  return (
    <If condition={data !== undefined}>
      <Then>
        {data !== undefined &&
          Object.keys(data).map((date: string) => {
            return (
              <DateContainer
                key={nanoid()}
                data={data[date]}
                date={date}
                theme={theme}
                dispatch={(date: string) => dispatch(setDateSlot(date))}
                selected={date === selectedDay}
              />
            )
          })}
      </Then>
    </If>
  )
})

interface DateContainerProps {
  data: any
  date: string
  theme: Theme
  dispatch: Function
  selected?: boolean
}
// ? Date Component
const DateContainer = memo(function DateContainer({
  data,
  date,
  theme,
  dispatch,
  selected = false
}: DateContainerProps): JSX.Element {
  const { daysOfWeek, day, active = false } = data
  const dow: string = daysOfWeek.toString()
  const style: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    aspectRatio: '1/1'
  }
  if (selected) {
    style.backgroundColor = theme?.palette.primary.main
    style.color = '#fff'
  }
  return (
    <IconButton
      disabled={active === false}
      onClick={() => dispatch(date)}
      style={style}
      className="date-container "
    >
      <Typography variant="body2">
        <Trans>{`subDaysOfWeek.${dow}`}</Trans>
      </Typography>
      <Typography variant="body1" fontWeight={700}>
        {day}
      </Typography>
    </IconButton>
  )
})

const LoadingDateContainer = memo(function LoadingDateContainer(): JSX.Element {
  const row = []

  for (let i = 0; i <= 6; i++) {
    row.push(
      <IconButton key={nanoid()} className="date-container ">
        <Skeleton variant="circular" width="100%" height="100%" />
      </IconButton>
    )
  }
  return <>{row}</>
})
