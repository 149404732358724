import { Stack, Typography } from '@mui/material'

export const Cookie = (): JSX.Element => {
  return (
    <>
      <Typography variant="pageTitle">Cookie Policy</Typography>
      <div className="body padding-top-m padding-bottom-l" id="body">
        <div className="default-page" style={{ textAlign: 'left' }}>
          <Typography variant="subtitle1">
            Ai sensi dell’articolo 13 Regolamento EU 679/2016 (d’ora in poi
            “Regolamento”) e dei Provvedimenti del Garante Privacy, Bludata
            Informatica S.r.l. fornisce le seguenti informazioni relative ai
            cookie installati su questo sito.
          </Typography>
          <Typography variant="h6" sx={{ p: '10px 0 5px' }}>
            Cosa sono i cookie?
          </Typography>
          <Typography variant="subtitle1">
            I cookie sono stringhe di testo create da un server e memorizzati
            sull’hard disk del computer o su qualsiasi dispositivo utilizzato
            dall’utente per accedere ad Internet (smartphone, tablet) per poi
            essere ritrasmessi ai successivi accessi ad Internet dell’utente
            stesso.
          </Typography>
          <Typography variant="subtitle1">
            I cookie permettono di raccogliere informazioni sulla navigazione
            effettuata dall’utente sul sito web. I cookie possono essere
            memorizzati in modo permanente sul computer e avere una durata
            variabile (c.d. cookies persistenti), ma possono anche svanire con
            la chiusura del browser o avere una durata limitata (c.d. cookies di
            sessione).
          </Typography>
          <Typography variant="subtitle1">
            I cookie possono essere istallati dal sito che sta visitando (c.d.
            cookie di prima parte) o da altri siti web che forniscono servizi di
            vario genere (c.d. cookie di terze parti).
          </Typography>
          <Typography variant="h6" sx={{ p: '10px 0 5px' }}>
            Questo sito come utilizza i cookie?
          </Typography>
          <Typography variant="subtitle1">
            Questo sito utilizza solo Cookie tecnici e di sessione (di prima
            parte) Tali cookie sono essenziali per la navigazione nella pagina e
            per l’utilizzo di tutte le sue funzionalità. Senza questi cookie non
            è possibile fruire di alcuni servizi necessari.
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 500, textDecoration: 'underline', pt: '5px' }}
          >
            Cookie tecnici di prima parte che non richiedono il consenso
            preventivo dell’utente.
          </Typography>
          <div style={{ padding: '10px 0' }}>
            <Stack
              flex="1"
              direction="row"
              justifyContent="space-between"
              gap={2}
            >
              <Stack flex="1" direction="column">
                <Typography variant="subtitle1" fontWeight="500">
                  Nome cookie
                </Typography>
                <Typography variant="subtitle1">
                  appointment_banner_cookie
                </Typography>
              </Stack>
              <Stack flex="2" direction="column">
                <Typography variant="subtitle1" fontWeight="500">
                  Finalità cookie
                </Typography>
                <Typography variant="subtitle1">
                  Gestione accettazione dei cookie.
                </Typography>
              </Stack>
              <Stack flex="1" direction="column">
                <Typography variant="subtitle1" fontWeight="500">
                  Persistente
                </Typography>
                <Typography variant="subtitle1">Nome cookie</Typography>
              </Stack>
            </Stack>
          </div>
          <Typography variant="h6" sx={{ p: '10px 0 5px' }}>
            Titolare del trattamento e Responsabile della Protezione dei Dati
          </Typography>
          <Typography variant="subtitle1">
            Il Titolare del trattamento è Bludata Informatica S.r.l., con sede a
            Breda di Piave (TV), Via delle Industrie, 10. Tel. 0422445442, Fax.
            0422699318, e-mail: info@bludata.com PEC: bludata@legalmail.it. Il
            Responsabile della Protezione dei Dati (RPD) è raggiungibile ai
            recapiti del Titolare del trattamento e al seguente indirizzo email:
            dpo@bludata.com.
          </Typography>
          <Typography variant="h6" sx={{ p: '10px 0 5px' }}>
            Diritti degli interessati
          </Typography>
          <Typography variant="subtitle1">
            La normativa a protezione dei dati personali prevede espressamente
            alcuni diritti in capo ai soggetti cui dati si riferiscono (c.d.
            interessato). In particolare, ai sensi degli articoli 15-22 del
            Regolamento (UE) 2016/679, ciascun interessato ha il diritto di
            conoscere l’elenco aggiornato dei Responsabili del trattamento, di
            ottenere la conferma dell’esistenza o meno dei medesimi dati e di
            conoscerne il contenuto, l’origine, verificarne l’esattezza o
            chiederne l’integrazione o l’aggiornamento, oppure la
            rettificazione. Ai sensi dei medesimi articoli, l’interessato ha il
            diritto di chiedere la cancellazione, la limitazione, la portabilità
            dei dati, revocare il consenso, nonché di opporsi, per motivi
            legittimi, al trattamento dei dati. Inoltre, ai sensi dell’art. 77
            del Regolamento, qualora l’interessato ritenga che il trattamento
            che lo riguarda violi il Regolamento, ha il diritto di proporre
            reclamo a un’autorità di controllo, segnatamente nello Stato membro
            in cui risiede abitualmente, lavora oppure si è verificata la
            presunta violazione. Tale autorità, per quanto riguarda il
            territorio italiano, è il Garante per la protezione dei dati
            personali. Per l’esercizio di tali diritti, l’interessato può
            rivolgersi al Titolare del trattamento scrivendo a Bludata
            Informatica S.r.l., Via delle Industrie, 10 Breda di Piave (TV).
            Tel. 0422445442, Fax. 0422699318, E-mail: privacy@bludata.com PEC:
            bludata@legalmail.it.
          </Typography>
          <Typography variant="subtitle1">
            Il Responsabile della Protezione dei Dati (RPD) è raggiungibile ai
            recapiti del Titolare del trattamento e al seguente indirizzo email:
            dpo@bludata.com Data ultima
          </Typography>
          <Typography variant="subtitle1">revisione: 13/04/2023</Typography>
        </div>
      </div>
    </>
  )
}
